"use strict";

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CookieStorage = void 0;

var format_cookie_1 = require("./format-cookie");

var parse_cookies_1 = require("./parse-cookies");

var CookieStorage = function () {
  function CookieStorage(defaultOptions) {
    _classCallCheck(this, CookieStorage);

    this._defaultOptions = Object.assign({
      domain: null,
      expires: null,
      path: null,
      secure: false
    }, defaultOptions);
    if (typeof Proxy !== "undefined") return new Proxy(this, cookieStorageHandler);
  }

  _createClass(CookieStorage, [{
    key: "clear",
    value: function clear() {
      var _this = this;

      var parsed = parse_cookies_1.parseCookies(this._getCookie());
      var keys = Object.keys(parsed);
      keys.forEach(function (key) {
        return _this.removeItem(key);
      });
    }
  }, {
    key: "getItem",
    value: function getItem(key) {
      var parsed = parse_cookies_1.parseCookies(this._getCookie());
      return Object.prototype.hasOwnProperty.call(parsed, key) ? parsed[key] : null;
    }
  }, {
    key: "key",
    value: function key(index) {
      var parsed = parse_cookies_1.parseCookies(this._getCookie());
      var sortedKeys = Object.keys(parsed).sort();
      return index < sortedKeys.length ? sortedKeys[index] : null;
    }
  }, {
    key: "removeItem",
    value: function removeItem(key, cookieOptions) {
      var data = "";
      var options = Object.assign(Object.assign(Object.assign({}, this._defaultOptions), cookieOptions), {
        expires: new Date(0)
      });
      var formatted = format_cookie_1.formatCookie(key, data, options);

      this._setCookie(formatted);
    }
  }, {
    key: "setItem",
    value: function setItem(key, data, options) {
      var opts = Object.assign(Object.assign({}, this._defaultOptions), options);
      var formatted = format_cookie_1.formatCookie(key, data, opts);

      this._setCookie(formatted);
    }
  }, {
    key: "_getCookie",
    value: function _getCookie() {
      return typeof document === "undefined" ? "" : typeof document.cookie === "undefined" ? "" : document.cookie;
    }
  }, {
    key: "_setCookie",
    value: function _setCookie(value) {
      document.cookie = value;
    }
  }, {
    key: "length",
    get: function get() {
      var parsed = parse_cookies_1.parseCookies(this._getCookie());
      var keys = Object.keys(parsed);
      return keys.length;
    }
  }]);

  return CookieStorage;
}();

exports.CookieStorage = CookieStorage;
var cookieStorageHandler = {
  defineProperty: function defineProperty(target, p, attributes) {
    target.setItem(p.toString(), String(attributes.value));
    return true;
  },
  deleteProperty: function deleteProperty(target, p) {
    target.removeItem(p.toString());
    return true;
  },
  get: function get(target, p, _receiver) {
    if (typeof p === "string" && p in target) return target[p];
    var result = target.getItem(p.toString());
    return result !== null ? result : undefined;
  },
  getOwnPropertyDescriptor: function getOwnPropertyDescriptor(target, p) {
    if (p in target) return undefined;
    return {
      configurable: true,
      enumerable: true,
      value: target.getItem(p.toString()),
      writable: true
    };
  },
  has: function has(target, p) {
    if (typeof p === "string" && p in target) return true;
    return target.getItem(p.toString()) !== null;
  },
  ownKeys: function ownKeys(target) {
    var keys = [];

    for (var i = 0; i < target.length; i++) {
      var key = target.key(i);
      if (key !== null) keys.push(key);
    }

    return keys;
  },
  preventExtensions: function preventExtensions(_) {
    throw new TypeError("can't prevent extensions on this proxy object");
  },
  set: function set(target, p, value, _) {
    target.setItem(p.toString(), String(value));
    return true;
  }
};