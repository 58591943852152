"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCookie = void 0;

var getSameSiteValue = function getSameSiteValue(o) {
  var sameSite = o.sameSite;
  if (typeof sameSite === "undefined") return null;
  if (["none", "lax", "strict"].indexOf(sameSite.toLowerCase()) >= 0) return sameSite;
  return null;
};

var formatOptions = function formatOptions(o) {
  var path = o.path,
      domain = o.domain,
      expires = o.expires,
      secure = o.secure;
  var sameSiteValue = getSameSiteValue(o);
  return [typeof path === "undefined" || path === null ? "" : ";path=" + path, typeof domain === "undefined" || domain === null ? "" : ";domain=" + domain, typeof expires === "undefined" || expires === null ? "" : ";expires=" + expires.toUTCString(), typeof secure === "undefined" || secure === false ? "" : ";secure", sameSiteValue === null ? "" : ";SameSite=" + sameSiteValue].join("");
};

var formatCookie = function formatCookie(k, d, o) {
  return [encodeURIComponent(k), "=", encodeURIComponent(d), formatOptions(o)].join("");
};

exports.formatCookie = formatCookie;